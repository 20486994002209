<template>
  <div class="mt-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
          class="btn-create mb-1"
          type="button"
          variant="primary"
          size="sm"
          @click="onAddBookEntry('brokers')"
          title="Crea"
          v-if="canVerb(resource, 'store')"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon> Crea Acconto
          Produttore</b-button
        >
        <b-button
          class="btn-create mb-1"
          type="button"
          variant="primary"
          size="sm"
          @click="onAddBookEntry('registries')"
          title="Crea"
          v-if="canVerb(resource, 'store')"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon> Crea Acconto
          Cliente</b-button
        >
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)" class="mt-1">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-select
                name="deposit"
                label="Mostra Acconti"
                :options="deposit"
                v-model="filter.byDeposited"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="salesman_id"
                label="Produttori"
                :multiple="true"
                :options="salesmen"
                v-model="filter.byBroker.id"
                :closeOnSelect="false"
                :taggable="salesmen_taggable"
                :defaultHtmlMultipleSelect="true"
                @changeTaggable="changeTaggable"
              />
            </div>
          </b-row>
          <b-row>
            <div class="col-md-3">
              <base-select
                name="companies"
                label="Compagnia"
                :options="companies"
                v-model="filter.byInsurer.id"
                :closeOnSelect="true"
              />
            </div>
            <div class="col-md-3">
              <base-input
                name="number"
                type="text"
                label="Numero Polizza"
                v-model="filter.byInsurancePolicy.number"
                placeholder="Inserisci un numero polizza"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="book_tags"
                label="Codice Documento"
                v-model="filter.byBookTag.id"
                :options="book_tags_opt"
              />
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3">
              <div>
                <b-button-toolbar>
                  <b-button-group>
                    <base-input
                      name="Contraente"
                      label="Contraente"
                      v-model="form.inpt_label_registry"
                      :readonly="true"
                    />
                    <b-button
                      class="mt-4 btn-quick"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      title="Ricerca Veloce"
                      @click="openQuickSearchRegistry"
                      ><b-icon icon="search"></b-icon
                    ></b-button>
                    <b-button
                      v-if="form.inpt_label_registry"
                      class="mt-4 btn-quick ml-1"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      @click="resetRegistryId"
                      title="Elimina Anagrafica"
                      ><b-icon icon="trash"></b-icon
                    ></b-button>
                  </b-button-group>
                </b-button-toolbar>
              </div>
            </div>
            <quick-search-registry-modal
              ref="quickSearchRegistry"
              @input="handleRegistryModalInput"
            >
            </quick-search-registry-modal>
            <div class="form-group col-md-3">
              <span
                v-if="registry_data"
                class="info"
                v-html="toInfoData(registry_data, 'registry')"
              >
              </span>
            </div>
            <div class="form-group col-md-3">
              <div>
                <b-button-toolbar>
                  <b-button-group>
                    <base-input
                      name="Gruppo Anagrafico"
                      label="Gruppo Anagrafico"
                      v-model="form.inpt_label_group"
                      :readonly="true"
                    />
                    <b-button
                      class="mt-4 btn-quick"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      title="Ricerca Veloce"
                      @click="openQuickSearchGroup"
                      ><b-icon icon="search"></b-icon
                    ></b-button>
                    <b-button
                      v-if="form.inpt_label_group"
                      class="mt-4 btn-quick ml-1"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      @click="resetGroupId"
                      title="Elimina Gruppo"
                      ><b-icon icon="trash"></b-icon
                    ></b-button>
                  </b-button-group>
                </b-button-toolbar>
              </div>
            </div>
            <quick-search-group-modal
              ref="quickSearchGroup"
              @input="handleGroupModalInput"
            ></quick-search-group-modal>
            <div class="form-group col-md-3">
              <span
                v-if="group_data"
                class="info"
                v-html="toInfoData(group_data, 'registry_group_full')"
              >
              </span>
            </div>
          </b-row>
          <b-row>
            <div class="col-md-3">
              <base-datepicker
                name="aperto_dal"
                label="Periodo di registrazione acconto dal"
                v-model="filter.byCalendar.from"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="aperto_al"
                label="Periodo di registrazione acconto al"
                v-model="filter.byCalendar.to"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="incasso_successivo"
                label="Periodo di recupero acconto dal"
                v-model="filter.byReduced.after_recovery"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="incasso_precedente"
                label="Periodo di recupero acconto al"
                v-model="filter.byReduced.before_recovery"
              />
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>

    <export-options
      :exportUrl="exportUrl"
      :repository="repository"
      :resource="resource"
      :tableRef="$refs[tableRef]"
      :filter="filter"
      :options="[
        {
          code: 'LISTACC',
          label: null,
          formats: ['csv', 'pdf'],
        },
      ]"
    ></export-options>

    <!-- <button-group-table></button-group-table> -->

    <book-entries-deposited
      :fields="fields"
      :repository="repository"
      :filterOn="{
        byRelations: [
          'byVariousAccounting',
          'byRegistry',
          'byBroker',
          'byTask',
          'byDocument',
          'byInsurancePolicy',
        ],
      }"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      :onlyActions="['edit', 'destroy', 'infomodal']"
      descending
      @destroy="onDestroy"
      @edit="onEdit"
      sortField="book_date"
    ></book-entries-deposited>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";
import QuickSearchGroupModal from "@/components/modals/quickSearchGroup";
import BookEntriesDeposited from "@/components/tables/BookEntriesDeposited";
// import ButtonGroupTable from "@/components/ButtonGroupTable";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseInput from "@/components/form/BaseInput";
import { toLocaleDate } from "@/utils/dates";
import { toInfoData /* , toRelationString */ } from "@/utils/transforms";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import FormMixin from "@/mixins/FormMixin";
import { mapGetters, mapActions } from "vuex";
import { toLocaleCurrency } from "@/utils/strings";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import ExportOptions from "@/components/ExportOptions";

export default {
  mixins: [CustomFiltersMixin, FormMixin, StorageGetterMixin],
  data() {
    return {
      exportUrl: null,
      filter: this.initFilter(),
      filterName: "filterAccountingDeposited",
      resource: "book_entries",
      repository: "book_entry",
      tableRef: "AccountingDepositedTableRef",
      salesmen: [],
      salesmen_taggable: true,
      notes: [],
      documents: [],
      companies: [],
      book_tags_opt: [],
      registry_group_data: null,
      registry_data: null,
      group_data: null,
      form: {
        inpt_label_registry: "",
        inpt_label_group: "",
        quick_value: "",
      },
      fields: [
        {
          key: "book_date",
          label: this.getDictionary("book_date", "book_entry"),
          sortable: true,
          sortKey: "book_date",
          formatter: (value) => toLocaleDate(value),
        },
        {
          key: "last_deposited",
          label: this.getDictionary("Ultimo Recupero"),
          sortable: true,
          sortKey: "byView.max",
        },
        {
          key: "insurer_id",
          label: this.getDictionary("insurer"),
          sortable: true,
          sortKey: "byInsurer.title",
          formatter: (value) => this.retriveInsurer(value),
        },
        {
          key: "various_accountings_insurance_ancillaries",
          label: this.getDictionary("insurance_policy_number", "book_entry"),
          sortable: false,
          sortKey: "byInsurancePolicy.number",
        },
        {
          key: "book_tags",
          label: "Descrizione",
          sortable: false,
          // sortKey: "byInsurancePolicy.number",
          formatter: (value) => this.retriveDescription(value),
        },
        {
          key: "registry",
          label: this.getDictionary("registry", "book_entry"),
          sortable: true,
          sortKey: "byRegistry.title",
        },
        {
          key: "broker",
          label: this.getDictionary("broker", "book_entry"),
          sortable: true,
          sortKey: "byBroker.BUID",
        },
        {
          key: "title",
          label: this.getDictionary("title", "book_entry"),
        },
        {
          key: "positive_deposited",
          label: this.getDictionary("Importo Acconto"),
          formatter: (value) => toLocaleCurrency(value),
          tdClass: "text-right",
          thClass: "text-right",
        },
        {
          key: "total_deposited",
          label: this.getDictionary("Residuo Da Recuperare"),
          formatter: (value) => toLocaleCurrency(value),
          tdClass: "text-right",
          thClass: "text-right",
        },
        {
          key: "note_counter",
          label: this.getDictionary("note"),
        },
        {
          key: "document_counter",
          label: this.getDictionary("document"),
        },
      ],
      deposit: [
        { value: "yes", text: "Aperti e parzialmente aperti" },
        { value: "no", text: "Chiusi e parzialmente recuperati" },
        { value: "reduced", text: "Parzialmente aperti" },
      ],
    };
  },
  components: {
    // ButtonGroupTable,
    BaseSelect,
    BaseDatepicker,
    BaseInput,
    BookEntriesDeposited,
    QuickSearchRegistryModal,
    QuickSearchGroupModal,
    ExportOptions,
  },
  methods: {
    toInfoData,
    retriveDescription(value) {
      console.log("value", value);
      return value?.[0]?.code && value?.[0]?.title
        ? `${value[0].code} ${value[0].title}`
        : "-";
    },
    retriveInsurer(value) {
      let insurer_text = "";
      this.companies.forEach((company) => {
        if (company.value === value) insurer_text = company.text;
      });
      return insurer_text ? insurer_text : "-";
    },
    changeTaggable(val) {
      this.salesmen_taggable = val;
    },
    onAddBookEntry(depositType) {
      this.$router.push({
        name: `book_entries.create`,
        params: { mode: "III", source: depositType },
      });
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          this.$refs[this.tableRef].fetch();
          this.$showSnackbar({
            preset: "success",
            text: "Movimento Eliminato con successo",
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onEdit(item) {
      this.$router.push({
        name: `${this.resource}.edit`,
        params: { id: `${item.id}`, item: item, mode: "III" },
      });
    },
    initFilter() {
      let init = {
        byAttribute: {},
        byView: "deposit",
        byRegistry: {
          id: null,
        },
        byBroker: {
          id: null,
        },
        byInsurer: {
          id: null,
        },
        byInsurancePolicy: {
          number: null,
        },
        byRegistryGroup: {
          code: null,
        },
        byBookTag: {
          id: null,
        },
        byDeposited: "yes",
        byCalendar: {
          from: null,
          to: null,
        },
        byReduced: {
          after_recovery: null,
          before_recovery: null,
        },
      };
      return init;
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.exportUrl = this.$refs[this.tableRef].loadFilter(
        this.$refs[this.tableRef].filterName
      );
      // quicksearch
      criteria = {};
      ["quickSearchPolicy", "quickSearchRegistry", "quickSearchGroup"].forEach(
        (element) => {
          criteria[element] = {
            label: "",
            formLabel: "",
            data: null,
            formData: null,
          };
        }
      );
      criteria.quickSearchPolicy.label = this.form.inpt_label_policy;
      criteria.quickSearchPolicy.formLabel = "form.inpt_label_policy";
      criteria.quickSearchPolicy.data = this.insurance_policy_data;
      criteria.quickSearchPolicy.formData = "insurance_policy_data";
      criteria.quickSearchRegistry.label = this.form.inpt_label_registry;
      criteria.quickSearchRegistry.formLabel = "form.inpt_label_registry";
      criteria.quickSearchRegistry.data = this.registry_data;
      criteria.quickSearchRegistry.formData = "registry_data";
      criteria.quickSearchGroup.label = this.form.inpt_label_group;
      criteria.quickSearchGroup.formLabel = "form.inpt_label_group";
      criteria.quickSearchGroup.data = this.group_data;
      criteria.quickSearchGroup.formData = "group_data";
      this.saveQuickSearchByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetRegistryId();
      this.resetGroupId();
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removeQuickSearchByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.$refs[this.tableRef].sortBy = "book_date";
      this.$refs[this.tableRef].sortDesc = true;
    },
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
    openQuickSearchRegistry() {
      this.filter.byRegistry.id = null;
      this.$bvModal.show("quickSearchRegistryModal");
    },
    handleRegistryModalInput(value) {
      this.filter.byRegistry.id = value.id;
      this.registry_data = value;
      this.form.inpt_label_registry = this.toInfoData(
        this.registry_data,
        "registryfullname"
      );
      this.$bvModal.hide("quickSearchRegistryModal");
    },
    resetRegistryId() {
      this.registry_data = null;
      this.form.inpt_label_registry = null;
      this.filter.byRegistry.id = null;
    },
    handleGroupModalInput(value) {
      this.filter.byRegistryGroup.code = value.code;
      this.group_data = value;
      this.form.inpt_label_group = this.toInfoData(
        this.group_data,
        "registry_group"
      );
      this.$bvModal.hide("quickSearchGroupModal");
    },
    openQuickSearchGroup() {
      this.filter.byRegistryGroup.code = null;
      this.$bvModal.show("quickSearchGroupModal");
    },
    resetGroupId() {
      this.group_data = null;
      this.form.inpt_label_group = null;
      this.filter.byRegistryGroup.code = null;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("quicksearch", {
      saveQuickSearchByName: "saveByName",
      removeQuickSearchByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    ...mapGetters("auth", {
      getInsurers: "insurers",
      getSalesmen: "salesmen",
      getBookTags: "book_tags",
    }),
  },
  // beforeMount() {
  //   // #1153 se non c'è già un filtro salvato, imposto i valori di default
  //   let found = this.loadFilter(this.filterName);
  //   if (!found) {
  //     this.$set(this, "filter", this.initFilter());
  //   } else {
  //     this.$set(this, "filter", found);
  //   }
  // },
  // beforeDestroy() {
  //   this.onClearFilter(this.filterName);
  //   /* Fix navigazione all'interno del modulo CONT */
  //   const filterName = "filterRegistryGroup";
  //   this.removeFilterByName(filterName);
  //   this.removePaginationByName(filterName);
  //   this.removeSortByName(filterName);
  // },
  mounted() {
    this.salesmen = this.getSalesmen();
  },
  created() {
    /* */
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("insurers")) {
      resources.push(
        this.$store
          .dispatch(`${path}insurers`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    } else {
      this.companies = this.getInsurers();
    }
    if (!this.hasLoadedResource("book_tags")) {
      resources.push(
        this.$store
          .dispatch(`${path}book_tags`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    } else {
      this.book_tags_opt = this.getBookTags();
    }
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          this.companies = this.getInsurers();
          this.book_tags_opt = this.getBookTags();
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
    /* */
  },
};
</script>
<style scoped></style>
